/* eslint-disable react-hooks/exhaustive-deps */
import {
  PricingAdjustmentType,
  PricingList,
  SurchargeAmountType,
  SurchargeApplyType,
  SurchargePricingRule,
  useGetSurchargePricingRuleLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { Button, Loading, TextInput } from "@src/common/components";
import React, { useContext, useEffect, useState } from "react";
import {
  CalculatorIcon,
  PlusCircleIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { displaySurchargeAmount } from "@src/pricing/utils/displaySurchargeAmount";
import { Select, SIZE, Value } from "baseui/select";
import DollarInput from "@src/common/components/Input/DollarInput";
import { useDebounce } from "usehooks-ts";
import classNames from "classnames";

const AddCustomLabel = "Add Custom Price";

export type EditableSurchargePricingRule = SurchargePricingRule & {
  note?: string;
};

const initialPricingRowValues: EditableSurchargePricingRule = {
  id: "",
  courierId: "",
  name: "Select",
  amount: "",
  percent: 0,
  amountType: SurchargeAmountType.Fixed,
  applyType: SurchargeApplyType.ManualOnly,
  amountDisplay: "",
  note: "",
  showInInvoice: false,
};

const SurchagePricingRulesForm = ({
  surchargePricings,
  setSurchargePricings,
  setValue,
  isCompactList = false,
  showAddButton = true,
  pricingList = undefined,
}: {
  surchargePricings: EditableSurchargePricingRule[];
  setSurchargePricings: (pricing: EditableSurchargePricingRule[]) => void;
  setValue: any;
  isCompactList?: boolean;
  showAddButton?: boolean;
  pricingList?: PricingList | undefined;
}) => {
  const { courierId } = useContext(AuthContext);
  const [getSurchargePricingRule, { loading }] =
    useGetSurchargePricingRuleLazyQuery();
  const [addState, setAddState] = useState(false);
  const [surchargePricingRules, setSurchargePricingRules] = useState<
    SurchargePricingRule[]
  >([]);
  const [selectedValues, setSelectedValues] = useState<Value[]>([]);
  const [editableRows, setEditableRows] = useState<
    EditableSurchargePricingRule[]
  >([]);
  const [customAmounts, setCustomAmounts] = useState<{
    [index: number]: number;
  }>({});
  const debouncedValue = useDebounce<any>(customAmounts, 800);
  useEffect(() => {
    const pricingAdjustments = surchargePricings.map((pricing) => {
      return {
        amount_total: pricing.id ? undefined : pricing.amount,
        note: pricing.note,
        surchargePricingRuleId: pricing.id || undefined,
        type: pricing.id
          ? PricingAdjustmentType.RuleBased
          : PricingAdjustmentType.Custom,
      };
    });
    setValue("pricingAdjustments", pricingAdjustments);
  }, [surchargePricings]);

  useEffect(() => {
    if (courierId) {
      const variables = {
        courierId: courierId,
        surchargeApplyType: SurchargeApplyType.ManualOnly,
        defaultPricingListOnly: true,
      };
      if (pricingList) {
        variables["defaultPricingListOnly"] = false;
        variables["pricingListIdsFilter"] = [pricingList.id];
      }
      getSurchargePricingRule({ variables }).then((res) => {
        if (res.data) {
          const surchargePricingRules = res.data.surchargePricingRules.map(
            (rule) => ({
              ...rule,
              amountDisplay: displaySurchargeAmount(
                rule.amountType,
                rule.amount,
                rule.percent
              ),
            })
          );
          setSurchargePricingRules(
            surchargePricingRules as SurchargePricingRule[]
          );
        }
      });
    }
  }, [courierId, pricingList]);

  const updateRow = (rule: SurchargePricingRule, index: number) => {
    let updatedRows = [...editableRows];
    updatedRows[index] = rule;
    setEditableRows(updatedRows);
    const filteredRows = updatedRows.filter((row) => row.amount !== "");
    setSurchargePricings(filteredRows);
  };

  const addRow = () => {
    setEditableRows([...editableRows, initialPricingRowValues]);
  };

  const deleteRow = (index: number) => {
    let updatedRows = [...editableRows];
    let updatedSelectedValues = [...selectedValues];

    updatedRows.splice(index, 1);
    updatedSelectedValues.splice(index, 1);

    setEditableRows(updatedRows);
    setSurchargePricings(updatedRows);
    setSelectedValues(updatedSelectedValues);
  };

  const updateCustomAmount = (amount: number, index: number) => {
    setCustomAmounts({
      ...customAmounts,
      [index]: amount,
    });
    let updatedRows = [...editableRows];
    updatedRows[index] = {
      ...updatedRows[index],
      amount: amount,
    };
    setEditableRows(updatedRows);
  };

  useEffect(() => {
    const filteredRows = editableRows.filter((row) => row.amount !== "");
    setSurchargePricings(filteredRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  const handleSelectChange = (params: any, index: number) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = params.value;
    setSelectedValues(newSelectedValues);

    const selectedRuleId = Array.isArray(params.value)
      ? params.value[0].id
      : params.value.id;

    const selectedRule = surchargePricingRules.find(
      (rule) => rule.id === selectedRuleId
    );
    if (selectedRule) {
      updateRow(selectedRule, index);
    } else {
      initialPricingRowValues.name = params.option.label;
      updateRow(initialPricingRowValues, index);
    }
  };

  const handleTextInputChange = (
    updatedRows: EditableSurchargePricingRule[]
  ) => {
    setEditableRows(updatedRows);
    setSurchargePricings(updatedRows);
  };

  const renderCompactList = () => {
    return (
      <>
        {editableRows.map((row, index) => (
          <div
            key={index}
            className="flex mb-1 overflow-auto justify-between border-t"
          >
            <div className="flex flex-col gap-1" style={{ width: 180 }}>
              {editableRows[index].name === AddCustomLabel ? (
                <div className="col-span-2">
                  <DollarInput
                    size={SIZE.mini}
                    dollar={Number(customAmounts[index]) || 0}
                    setDollar={(dollar) => updateCustomAmount(dollar, index)}
                  />
                </div>
              ) : (
                <span className="text-xs col-span-2 pl-3 pt-1">
                  {row.amountDisplay}
                </span>
              )}
              <Select
                backspaceRemoves={false}
                clearable={false}
                deleteRemoves={false}
                escapeClearsValue={false}
                size={SIZE.mini}
                options={{
                  Custom: [{ label: AddCustomLabel, id: "" }],
                  Surcharges: surchargePricingRules.map((rule) => ({
                    label: rule.name,
                    id: rule.id,
                  })),
                }}
                searchable={false}
                value={selectedValues[index]}
                placeholder="Select rule"
                onChange={(params: any) => {
                  handleSelectChange(params, index);
                }}
              />
            </div>
            <Button
              size={"xs"}
              color={"secondary"}
              onClick={() => deleteRow(index)}
            >
              <TrashIcon className="h-5 w-5 inline-block cursor-pointer hover:bg-slate-200 rounded-full p-0.5" />
            </Button>
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="mx-auto max-w-3xl">
      <Loading
        loading={loading}
        className="rounded-md mx-auto"
        style={{ maxWidth: 400 }}
      >
        {isCompactList ? (
          renderCompactList()
        ) : (
          <div className="p-4">
            <ol className="relative text-gray-500 border-l border-gray-200 dark:border-gray-700 dark:text-gray-400">
              {editableRows.map((row, index) => (
                <li className="mb-8 ml-6" key={index}>
                  <span className="absolute flex items-center justify-center w-8 h-8 bg-green-200 rounded-full -left-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900">
                    <CalculatorIcon className="w-5 h-5 text-green-600" />
                  </span>
                  <div className="grid grid-cols-8 gap-2">
                    <div className="col-span-2">
                      <Select
                        backspaceRemoves={false}
                        clearable={false}
                        deleteRemoves={false}
                        escapeClearsValue={false}
                        size={SIZE.mini}
                        options={{
                          Custom: [{ label: AddCustomLabel, id: "" }],
                          Surcharges: surchargePricingRules.map((rule) => ({
                            label: rule.name,
                            id: rule.id,
                          })),
                        }}
                        searchable={false}
                        value={selectedValues[index]}
                        placeholder="Select rule"
                        onChange={(params: any) => {
                          handleSelectChange(params, index);
                        }}
                      />
                    </div>

                    {editableRows[index].name === AddCustomLabel ? (
                      <div className="col-span-2">
                        <DollarInput
                          size={SIZE.mini}
                          dollar={Number(customAmounts[index]) || 0}
                          setDollar={(dollar) =>
                            updateCustomAmount(dollar, index)
                          }
                        />
                      </div>
                    ) : (
                      <span className="text-base col-span-2 pl-3 pt-1">
                        {row.amountDisplay}
                      </span>
                    )}

                    <div className="col-span-3">
                      {row && (
                        <TextInput
                          placeholder={"Enter an optional note"}
                          sizing={"sm"}
                          value={row.note ? row.note : ""}
                          onChange={(e) => {
                            let updatedRows = [...editableRows];
                            updatedRows[index] = {
                              ...updatedRows[index],
                              note: e.target.value,
                            };
                            handleTextInputChange(updatedRows);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-span-1">
                      <Button
                        size={"xs"}
                        color={"secondary"}
                        onClick={() => deleteRow(index)}
                        className="ml-2"
                      >
                        <TrashIcon className="h-5 w-5 text-red-500" />
                      </Button>
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="flex gap-3">
          {showAddButton && (
            <Button
              size={"xs"}
              color={"secondary"}
              onClick={addRow}
              className={classNames({
                "cursor-pointer text-blue-500 hover:underline": true,
                "my-2": isCompactList,
              })}
            >
              <PlusCircleIcon className="h-5 w-5 mr-2" />
              Add Surcharge Price
            </Button>
          )}
          {addState && (
            <Button
              size={"xs"}
              color={"secondary"}
              onClick={() => {
                setAddState(false);
              }}
            >
              <XMarkIcon className="h-5 w-5 mr-2" />
              Cancel
            </Button>
          )}
        </div>
      </Loading>
    </div>
  );
};

export default SurchagePricingRulesForm;
