import { SurchargeAmountType } from "@api/graphql/generated/generated-types";

export function displaySurchargeAmount(
  type: SurchargeAmountType,
  amount: string,
  percent: number
): string {
  switch (type) {
    case SurchargeAmountType.Fixed:
      return `$${amount} Flat`;
    case SurchargeAmountType.PercentageOnMilesCharge:
      return `${percent}% of Miles Charge`;
    case SurchargeAmountType.PerMile:
      return `$${amount} / mile`;
    case SurchargeAmountType.PerWaitTimeMinutes:
      return `$${amount} / minute`;
    case SurchargeAmountType.PerPackageLbs:
      return `$${amount} / lbs`;
    case SurchargeAmountType.PerPackagePiece:
      return `$${amount} / piece`;
    case SurchargeAmountType.PercentageOnTotalExceptSpecifiedSurcharges:
      return `${percent}% of Total`;
    case SurchargeAmountType.PerMilesOutOfPerimeter:
      return `$${amount} / mile out of perimeter`;
    default:
      return "";
  }
}
