export function metersToMiles(meters: number): number {
  const miles = meters / 1609.344; // 1 mile = 1609.344 meters
  return miles;
}

export function roundToOneDecimal(num: number): number {
  return Math.round(num * 10) / 10;
}

export const roundToTwoDecimalsIfFractional = (num: number): number =>
  num % 1 === 0 ? num : +num.toFixed(2);
